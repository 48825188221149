.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "IBM Plex Sans", "Helvetica Neue", Arial, san-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 1;
  background-color: #f5f5f5;
  border-radius: 16px;
  padding: 8px 7px;
}

.text {
  margin: 0;
  color: #212121;
  word-break: break-all;
}

.button {
  background-color: transparent;
  border: none;
  outline: none;
  height: 16px;
  margin: 0 0 0 6px;
  padding: 0;
  cursor: pointer;
}

.button > svg {
  color: #bdbdbd;
}

.button:hover > svg {
  color: #606060;
}
