.container {
  display: flex;
  flex-direction: column;
}

.container.disabled {
  pointer-events: none;
}

.sliderWrapper {
  display: flex;
  align-items: center;
}

.highPriority {
  color: #212121;
  margin: 0 20px 0 0;
}

.container.disabled .highPriority {
  color: #c0c0c0;
}

.lowPriority {
  color: #9e9e9e;
  margin: 0 0 0 20px;
}

.container.disabled .lowPriority {
  color: #c0c0c0;
}

.resetButton {
  display: flex;
  align-items: center;
  align-self: flex-end;
  font-family: "IBM Plex Sans", "Helvetica Neue", Arial, san-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  color: #606060;
  margin: 40px 0 0;
  border: none;
  background: none;
  cursor: pointer;
}

.container.disabled .resetButton {
  color: #c0c0c0;
}

.resetButton:hover {
  text-decoration: underline;
}

.refreshIcon {
  color: #606060;
  margin: 0 10px 0 0;
}

.container.disabled .refreshIcon {
  color: #c0c0c0;
}

.slider :global(.rc-slider-handle),
.slider :global(.rc-slider-handle):hover {
  border: 2px solid #606060 !important;
}

.slider :global(.rc-slider-handle):active {
  border-color: #606060 !important;
  box-shadow: 0 0 5px #606060 !important;
  cursor: grabbing;
}

.sliderMark {
  font-family: "IBM Plex Sans", "Helvetica Neue", Arial, san-serif;
  font-weight: 600;
  font-size: 12px;
  line-height: 1;
  color: #212121;
}

.container.disabled .sliderMark {
  color: #c0c0c0;
}
