.inputContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px dashed #e0e0e0;
  border-radius: 6px;
  background-color: rgba(33, 33, 33, 0.02);
  padding: 34px;
}

.inputContainer.disabled {
  pointer-events: none;
}

.description {
  font-family: "IBM Plex Sans", "Helvetica Neue", Arial, san-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.2;
  text-align: center;
  color: #606060;
  margin: 8px 0;
}

.disabled .description {
  color: #c0c0c0;
}

.buttonWrapper {
  display: inline-block;
  cursor: pointer;
  position: relative;
  z-index: 1;
}

button.disabledButton {
  color: #c0c0c0 !important;
  border-color: #c0c0c0 !important;
}

.fileInput {
  display: block;
  width: 100%;
  border: none;
  text-transform: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
}

.fileInput:focus {
  outline: none;
}

.icon {
  color: #bdbdbd;
}

.previewContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  margin: -8px auto -8px -8px;
}

.filePreview {
  display: flex;
  align-items: center;
  margin: 8px;
}

.filePreview img {
  width: 75px;
  height: 50px;
  border-radius: 6px;
  margin: 0 8px 0 0;
  object-fit: cover;
}

.filePreview span {
  font-family: "IBM Plex Sans", "Helvetica Neue", Arial, san-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.2;
  color: #606060;
  word-break: break-all;
  /* To clamp the text down to 2 lines if it's any longer (supported in all major browsers apart from IE as usual) */
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.confirmationButtonWrapper {
  display: flex;
  margin: 24px -4px -4px;
}

.confirmationButtonWrapper button {
  margin: 4px;
}

.errorText {
  display: inline-block;
  font-family: "IBM Plex Sans", "Helvetica Neue", Arial, san-serif;
  font-weight: 500;
  font-size: 11px;
  line-height: 1.2;
  color: #e53935;
  margin: 8px 0 0;
}
