.container {
  display: flex;
  align-items: center;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  padding: 18px 18px 18px 14px;
  cursor: pointer;
}

.container.disabled {
  pointer-events: none;
  background-color: rgba(33, 33, 33, 0.04);
}

.container:hover {
  background-color: rgba(33, 33, 33, 0.04);
}

.label {
  display: inline-block;
  font-family: "IBM Plex Sans", "Helvetica Neue", Arial, san-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.2;
  color: #606060;
  margin: 0 0 8px;
}

.icon {
  align-self: flex-start;
}

.chevron {
  display: flex;
  align-items: center;
  justify-content: center;
}

.chevron > svg {
  color: #606060;
}

.contentWrapper {
  flex: 1;
  margin: 0 20px 0 14px;
}
