.container {
  background-color: #fff;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
}

.resultItem {
  display: grid;
  gap: 32px;
  grid-template-columns: 1fr auto;
  padding: 14px 24px;
  cursor: pointer;
  border-bottom: 1px solid #e0e0e0;
}

.resultItem:hover {
  background-color: rgba(33, 33, 33, 0.06);
}

.resultItem:last-child {
  margin: 0;
}

.name,
.category,
.address,
.additionalInfo {
  font-family: "IBM Plex Sans", "Helvetica Neue", Arial, san-serif;
  font-weight: 500;
  line-height: 1.2;
}

.name {
  font-size: 15px;
  color: #212121;
  margin: 0 0 10px;
}

.category,
.address,
.additionalInfo {
  font-size: 14px;
  color: #606060;
  margin: 0 0 6px;
}

.category:last-child,
.address:last-child,
.additionalInfo:last-child {
  margin: 0;
}

.additionalInfo {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.additionalInfo span {
  position: relative;
}

.additionalInfo span::after {
  content: "\2022";
  margin: 0 4px;
}

.additionalInfo span:last-child::after {
  content: none;
}

.image {
  width: 90px;
  height: 90px;
  object-fit: cover;
  border-radius: 6px;
}

.noResults {
  font-family: "IBM Plex Sans", "Helvetica Neue", Arial, san-serif;
  font-weight: 400;
  text-align: center;
  line-height: 1.2;
  font-size: 16px;
  color: #606060;
  padding: 16px;
}
