.wrapper {
  position: relative;
  border-radius: 6px;
  width: 100%;
  overflow: hidden;
  display: flex;
}

.wrapper:hover .closeButton {
  display: flex;
  align-items: center;
  justify-content: center;
}

.image {
  width: 100%;
}

.closeButton {
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
  display: none;
  background-color: rgba(33, 33, 33, 0.24);
  border: none;
  border-radius: 50%;
  outline: none;
  padding: 6px;
  z-index: 2;
}

.closeButton:hover {
  background-color: rgba(33, 33, 33, 0.48);
}

.closeButton > svg {
  color: #fff;
}
