.container {
  font-family: "IBM Plex Sans", "Helvetica Neue", Arial, san-serif;
  border: none;
  outline: none;
  padding: 8px 16px;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.005em;
  text-decoration: none;
  border-radius: 6px;
  cursor: pointer;
}

.primary {
  background-color: #3c78ff;
  color: #fff;
}

.primary:hover,
.primary.active {
  background-color: #3f60ff;
}

.primary-ghost {
  background-color: transparent;
  border: 1px solid #3c78ff;
  color: #3c78ff;
}

.primary-ghost:hover,
.primary-ghost.active {
  background-color: rgba(60, 120, 255, 0.06);
}

.primary-transparent {
  background-color: transparent;
  color: #3c78ff;
}

.primary-transparent:hover,
.primary-transparent.active {
  background-color: rgba(60, 120, 255, 0.12);
}

.secondary {
  background-color: rgba(33, 33, 33, 0.06);
  color: #212121;
}

.secondary:hover,
.secondary.active {
  background-color: rgba(33, 33, 33, 0.12);
}

.secondary-ghost {
  background-color: transparent;
  border: 1px solid #e0e0e0;
  color: #606060;
}

.secondary-ghost:hover,
.secondary-ghost.active {
  background-color: rgba(33, 33, 33, 0.04);
  color: #212121;
}

.secondary-transparent {
  background-color: transparent;
  color: #606060;
}

.secondary-transparent:hover,
.secondary-transparent.active {
  background-color: rgba(33, 33, 33, 0.06);
  color: #212121;
}

.tertiary {
  background-color: #e53935;
  color: #fff;
}

.tertiary:hover,
.tertiary.active {
  background-color: #d50000;
}
