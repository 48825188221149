@import "@livingmap/core-ui-v2";

.container {
  width: 100%;
  display: flex;
  height: 100%;
  flex-direction: column;
}

.loaderContainer {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contentContainer {
  display: flex;
  height: 100%;
  overflow-y: scroll;

  .noShortlinksContainer {
    padding: 16px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;

    > span {
      @include font-headline-medium;
    }
  }

  .shortlinkPanelsContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 16px;
    padding: 24px;
    overflow: auto;
  }
}

.createShortlinkDialog {
  display: flex;
  flex-direction: column;

  .createShortlinkHeader {
    padding: 24px 24px 24px 20px;

    > span {
      @include font-headline-small;
    }
  }

  .createShortlinkBody {
    padding: 8px 24px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .formField {
      display: flex;
      flex-direction: column;
      gap: 4px;
      align-self: stretch;

      .fieldTitle {
        @include font-label-small;
      }

      .fieldInlineControl {
        display: flex;
        align-items: center;
        gap: 4px;

        > span {
          flex: 1;
          white-space: nowrap;
          @include font-body-large;
        }

        input {
          width: 120px;
        }
      }

      > ul {
        color: $red-tone-600;
        @include font-body-small;
        padding: 0;
        margin: 0;
        list-style-type: none;
      }
    }
  }

  .createShortlinkFooter {
    padding: 24px;
    display: flex;
    justify-content: flex-end;
    gap: 12px;
  }
}