.container {
  width: 100%;
  display: flex;
  flex: 1;
  overflow: hidden;
}

.gridContainer {
  border-right: 1px solid #e0e0e0;
  width: 100%;
  display: flex;
}
