.container {
  font-family: "IBM Plex Sans", "Helvetica Neue", Arial, san-serif;
  padding: 20px 24px;
}

.heading {
  font-weight: 600;
  font-size: 15px;
  line-height: 1;
  color: #212121;
  margin: 0 0 16px;
}

.description {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.2;
  color: #424242;
}
