.container {
  font-family: "IBM Plex Sans", "Helvetica Neue", Arial, san-serif;
  padding: 20px 24px;
}

.heading {
  font-weight: 600;
  font-size: 15px;
  line-height: 1;
  color: #212121;
  margin: 0 0 16px;
}

.description {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.2;
  color: #424242;
}

.inputSwitch {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.2;
  color: #606060;
  text-align: center;
}

.inputSwitch span {
  color: #3c78ff;
  cursor: pointer;
}

.inputSwitch span:hover {
  text-decoration: underline;
}

.buttonWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin: 20px -6px -6px;
}

.buttonWrapper > button {
  margin: 6px;
}

.alertContainer {
  margin-top: 15px;
}

.alertContainer ul {
  margin: 0;
  padding: 0 18px;
}

.alertContainer li {
  margin: 0;
  padding: 0;
}
