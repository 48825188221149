.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.label {
  font-family: "IBM Plex Sans", "Helvetica Neue", Arial, san-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.2;
  color: #606060;
  margin: 0 0 8px;
}

.textarea {
  font-family: "IBM Plex Sans", "Helvetica Neue", Arial, san-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.2;
  color: #212121;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  outline: none;
  padding: 10px 16px;
  width: 100%;
  resize: vertical;
  min-height: 38px;
}

.textarea:focus {
  outline: 2px solid #212121;
  outline-offset: -1px;
}

.textarea:disabled {
  background-color: #f5f5f5;
  color: #606060;
}
