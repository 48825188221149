@import "@livingmap/core-ui-v2";

.container {
  border-radius: 8px;
  border: 1px solid $grey-tone-200;
  background-color: $white-tone;
  padding: 16px 24px 16px 20px;
  display: flex;
  gap: 16px;

  .qrCode {
    width: 88px;
  }

  .details {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .title {
      @include font-title-medium;
      color: $grey-tone-900;
    }

    .link {
      @include font-label-small;
      color: $grey-tone-600;
      display: flex;
      align-items: center;
      gap: 8px;
      
      > svg:last-child {
        cursor: pointer;
      }
    }

    .history {
      @include font-label-small;
      color: $grey-tone-600;
      display: flex;
      align-items: center;
      gap: 8px;

      > div {
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }

    .icon {
      color: $grey-tone-600;
    }
  }

  .controls {
    display: flex;
    align-items: center;

    .editButton > svg {
      color: $grey-tone-600 !important;
    }
  }
}