.container {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  border-radius: 24px;
  padding: 8px;
  cursor: pointer;
}

.container:hover {
  background-color: rgba(33, 33, 33, 0.06);
}

.container.expanded {
  width: 100%;
}

.container > svg {
  margin: 0 10px 0 0;
}

.details {
  display: flex;
}

.initialWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  background-color: #212121;
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.initialWrapper span {
  font-family: "IBM Plex Sans", "Helvetica Neue", Arial, san-serif;
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  line-height: 1;
}

.nameWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: "IBM Plex Sans", "Helvetica Neue", Arial, san-serif;
  font-weight: 500;
  line-height: 1.2;
  margin: 0 0 0 8px;
}

.username {
  font-size: 15px;
  color: #212121;
}

.company {
  font-size: 12px;
  color: #606060;
}

.moreIcon {
  color: #606060;
}
