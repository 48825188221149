.container {
  margin: 0;
  padding: 0;
  background-color: #fff;
  width: 35px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 1px 4px rgba(33, 33, 33, 0.16),
    0px 8px 32px rgba(33, 33, 33, 0.16);
  border-radius: 6px;
  list-style-type: none;
  overflow: hidden;
}

.listItem {
  width: 100%;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  font-family: "IBM Plex Sans", "Helvetica Neue", Arial, san-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: 0.005em;
  text-align: center;
  color: #606060;
}

.active {
  background: rgba(60, 120, 255, 0.12);
  color: #212121;
}
