.panel {
  box-shadow: 0 8px 32px rgba(33, 33, 33, 0.16),
    0 1px 4px rgba(33, 33, 33, 0.16);
  border-radius: 6px;
  overflow: hidden;
  z-index: 999999;
  position: relative;
}

.panel > div {
  margin: 0;
  padding: 0;
}

.enter {
  transition: opacity 200ms ease-out;
}

.enterFrom {
  opacity: 0;
}

.enterTo {
  opacity: 1;
}

.leave {
  transition: opacity 200ms ease-out;
}

.leaveFrom {
  opacity: 1;
}

.leaveTo {
  opacity: 0;
}
