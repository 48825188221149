.container {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  height: 100%;
  width: 420px;
  min-width: 420px;
}

.loadingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 420px;
  min-width: 420px;
}

.sidebarTop {
  border-bottom: 1px solid #e0e0e0;
  padding: 20px 24px 0;
}

.sidebarHeading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sidebarTitle {
  font-family: "IBM Plex Sans", "Helvetica Neue", Arial, san-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 1;
  color: #212121;
  margin: 0;
}

.topButtonWrapper {
  display: flex;
  align-items: center;
}

.topButtonWrapper .topButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 0;
  cursor: pointer;
}

.topButtonWrapper .topButton:first-child {
  margin: 0 4px 0 0;
}

.topButtonWrapper .topButton:hover {
  background-color: rgba(33, 33, 33, 0.06);
}

.topButtonWrapper .topButton:hover .topButtonIcon {
  color: #212121;
}

.topButtonIcon {
  color: #606060;
}

.topPopoverContainer {
  font-family: "IBM Plex Sans", "Helvetica Neue", Arial, san-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 1;
  list-style: none;
  background-color: #fff;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.topPopoverContainer li {
  display: block;
  padding: 8px 12px;
  text-decoration: none;
  color: #212121;
  cursor: pointer;
}

.topPopoverContainer li.disabled {
  pointer-events: none;
  color: #e0e0e0 !important;
}

.topPopoverContainer li:hover {
  background-color: rgba(33, 33, 33, 0.06);
}

.topPopoverContainer li:first-child {
  margin-top: 8px;
}

.topPopoverContainer li:last-child {
  position: relative;
  color: #d32f2f;
}

.topPopoverContainer li:last-child {
  margin: 16px 0 8px;
}

.topPopoverContainer li:last-child:hover {
  background-color: rgba(183, 28, 28, 0.06);
}

.topPopoverContainer li:last-child::before {
  content: "";
  position: absolute;
  top: -8px;
  left: 0;
  width: 100%;
  border-top: 1px solid #e0e0e0;
}

.pillWrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 4px -4px;
}

.pillWrapper > div {
  margin: 4px;
}

.tabs {
  display: flex;
  align-items: center;
  margin: 0 -4px 8px;
}

.tab {
  display: inline-block;
  font-family: "IBM Plex Sans", "Helvetica Neue", Arial, san-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.005em;
  color: #606060;
  background-color: transparent;
  border-radius: 6px;
  border: none;
  outline: none;
  margin: 4px;
  padding: 6px 12px;
  cursor: pointer;
}

.tab:hover {
  background-color: rgba(33, 33, 33, 0.06);
}

.tab.active {
  background-color: rgba(33, 33, 33, 0.06);
  color: #212121;
}

.sidebarMiddle {
  flex: 1;
  overflow-y: scroll;
}

.sidebarBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #e0e0e0;
  padding: 16px 24px;
}

.saveCancelButtonContainer {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  margin-left: auto;
}
