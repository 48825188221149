@import-normalize; /* bring in normalize.css styles */
@import url("./fonts/fonts.css");

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body,
#root,
#root > div {
  height: 100%;
}
