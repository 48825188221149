.container {
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  padding: 8px;
  max-height: 340px;
  overflow-y: auto;
}

.label {
  font-family: "IBM Plex Sans", "Helvetica Neue", Arial, san-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.2;
  color: #606060;
  margin: 0 0 8px;
  display: block;
}

.container.disabled {
  pointer-events: none;
}

.container > div,
.container > input {
  margin: 4px;
}

.input {
  font-family: "IBM Plex Sans", "Helvetica Neue", Arial, san-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 1;
  color: #212121;
  border: none;
  margin: 0;
  padding: 8px 7px;
}

.container.disabled .input {
  color: #c0c0c0;
}

.input::placeholder {
  color: #606060;
}

.container.disabled .input::placeholder {
  color: #c0c0c0;
}

.input:focus {
  outline: none;
}

.limitText,
.limitError {
  display: block;
  font-family: "IBM Plex Sans", "Helvetica Neue", Arial, san-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 1;
  color: #606060;
  text-align: right;
  margin: 8px 0 0;
}

.limitText.disabled {
  color: #c0c0c0;
}

.limitError {
  color: #e53935;
}
