.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  padding: 0;
  background-color: rgba(33, 33, 33, 0.06);
}

.menu {
  font-family: "IBM Plex Sans", "Helvetica Neue", Arial, san-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 1;
  list-style: none;
  background-color: #fff;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.menu li {
  display: block;
  padding: 8px 12px;
  text-decoration: none;
  color: #212121;
  cursor: pointer;
}

.menu li.disabled {
  pointer-events: none;
  color: #e0e0e0 !important;
}

.menu li:hover {
  background-color: rgba(33, 33, 33, 0.06);
}

.menu li:first-child {
  margin-top: 8px;
}

.menu li:last-child {
  margin-bottom: 8px;
}

.menu li.deleteButton {
  position: relative;
  color: #d32f2f;
}

.menu li.deleteButton {
  margin: 16px 0 8px;
}

.menu li.deleteButton:hover {
  background-color: rgba(183, 28, 28, 0.06);
}

.menu li.deleteButton::before {
  content: "";
  position: absolute;
  top: -8px;
  left: 0;
  width: 100%;
  border-top: 1px solid #e0e0e0;
}
