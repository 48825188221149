.container {
  position: relative;
  padding: 16px;
}

.backdrop {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
  border-bottom: 1px solid #e0e0e0;
}

.label {
  position: relative;
  width: 100%;
}

.searchIcon {
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
  color: #606060;
}

.clearIcon {
  display: none;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}

.clearIcon:hover > svg {
  color: #212121;
}

.clearIcon > svg {
  color: #606060;
}

.input {
  font-family: "IBM Plex Sans", "Helvetica Neue", Arial, san-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.2;
  color: #606060;
  border: 1px solid transparent;
  border-radius: 22px;
  outline: none;
  width: 100%;
  padding: 8px 38px 8px 42px;
  box-shadow: 0 8px 32px rgba(33, 33, 33, 0.16),
    0 1px 4px rgba(33, 33, 33, 0.16);
}

.active .input {
  border: 1px solid #e0e0e0;
  box-shadow: none;
}

.active .clearIcon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.active .backdrop {
  display: block;
}
