.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 380px;
}

.sidebarWrapper {
  position: relative;
  height: 100%;
  overflow-y: scroll;
}

.sidebarWrapper > div {
  width: 100%;
  height: 100%;
}

.loaderWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
