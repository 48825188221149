.section {
  padding: 20px 24px;
  border-bottom: 1px solid #e0e0e0;
}

.section:last-child {
  border: none;
}

.sectionHeading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 18px;
}

.sectionTitle {
  font-family: "IBM Plex Sans", "Helvetica Neue", Arial, san-serif;
  font-weight: 600;
  font-size: 15px;
  line-height: 1;
  color: #212121;
  margin: 0;
}

.fieldsWithIcon {
  display: flex;
  align-items: flex-start;
}

.tooltipIcon {
  color: #606060;
}

.iconWrapper {
  margin: 38px 20px 0 0;
}

.closeIcon {
  align-self: center !important;
}

.fieldWrapper {
  width: 100%;
}

.formFieldSection {
  margin: 16px 0;
}

.formFieldSection.split {
  display: grid;
  gap: 12px;
  grid-template-columns: 2fr 1.5fr;
}

.operatingHoursContainer {
  font-family: "IBM Plex Sans", "Helvetica Neue", Arial, san-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.2;
  color: #212121;
}

.operatingHoursRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.operatingHoursRow > span {
  margin: 4px 0;
}

.operatingHoursRow > span:first-child {
  margin-right: 8px;
}

.richCtaText {
  font-family: "IBM Plex Sans", "Helvetica Neue", Arial, san-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.2;
  color: #212121;
  margin: 0;
}

.spacer {
  margin-top: 32px;
}
