.container {
  font-family: "IBM Plex Sans", "Helvetica Neue", Arial, san-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 1;
  list-style: none;
  background: #fff;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.menuItem {
  display: block;
  padding: 8px 12px;
  text-decoration: none;
  color: #212121;
  white-space: nowrap;
  cursor: pointer;
}

.menuItem:hover,
.menuItem.activeItem {
  background-color: #e0e0e0;
}

.container > li:first-child .menuItem {
  margin-top: 8px;
}

.container > li:last-child {
  margin-top: 8px;
}

.container > li:last-child .menuItem {
  margin: 8px 0;
}
