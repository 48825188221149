.container {
  height: 100%;
}

.container > div:first-of-type {
  height: 100%;
}

.base {
  display: flex;
  height: 100%;
}
