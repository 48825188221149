.contentContainer {
  padding: 20px 24px 16px;
}

.description {
  font-family: "IBM Plex Sans", "Helvetica Neue", Arial, san-serif;
  font-weight: 400;
  font-size: 14px;
  color: #212121;
  margin: 0 0 16px;
}

.description span {
  font-weight: 600;
}

.buttonWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin: 20px -6px -6px;
}

.buttonWrapper > button {
  margin: 6px;
}

.dropdownContainer {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(2, 1fr);
}

.checkboxContainer {
  display: flex;
  align-items: center;
  margin: 18px 0;
}

.checkboxContainer > div:first-child {
  margin: 0 16px 0 0;
}

.hidden {
  visibility: hidden;
}

.daySelector {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dayHeading {
  font-family: "IBM Plex Sans", "Helvetica Neue", Arial, san-serif;
  font-weight: 600;
  font-size: 15px;
  line-height: 1;
  color: #212121;
}

.selectorWrapper {
  display: flex;
  align-items: center;
}

.selectorButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  outline: none;
  border: none;
  background-color: transparent;
}

.selectorButton:first-child {
  margin: 0 8px 0 0;
}

.selectorButton:hover {
  background-color: rgba(33, 33, 33, 0.06);
}

.selectorButton:hover > svg {
  color: #212121;
}

.selectorButton > svg {
  color: #606060;
}

.heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 24px;
  border-bottom: 1px solid #e0e0e0;
}

.title {
  font-family: "IBM Plex Sans", "Helvetica Neue", Arial, san-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 1;
  color: #212121;
  margin: 0;
}

.closeButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  outline: none;
  border: none;
  background-color: transparent;
}
