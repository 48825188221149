.container {
  width: 100%;
  display: flex;
  height: 100%;
  flex-direction: column;
}

.loaderContainer {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
