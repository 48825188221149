.container {
  width: 100%;
  display: flex;
  flex: 1;
  overflow: hidden;
}

.mapContainer {
  position: relative;
  width: 100%;
  height: 100%;
}

.searchWrapper {
  position: absolute;
  top: 0;
  z-index: 90;
}

.fullHeight {
  height: 100%;
}
