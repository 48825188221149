.container {
  background: #fff;
  width: 100%;
}

.menuContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e0e0e0;
  padding: 12px 18px;
  min-height: 70px;
}

.buttonWrapper {
  display: flex;
  align-items: center;
}

.changes,
.published {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  height: 28px;
  width: 28px;
  margin: 0 0 0 8px;
}

.changes {
  font-family: "IBM Plex Sans", "Helvetica Neue", Arial, san-serif;
  font-weight: 700;
  font-size: 12px;
  line-height: 1;
  color: #fff;
  background-color: #f57c00;
}

.published {
  background-color: #178f3c;
}

.publishedIcon {
  color: #fff;
}
