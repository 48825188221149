.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.container.disabled {
  pointer-events: none;
}

.selectedIcon {
  width: 18px;
  height: 18px;
  margin: 0 10px 0 0;
}

.selectedSpan {
  color: #212121;
}

.label {
  font-family: "IBM Plex Sans", "Helvetica Neue", Arial, san-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.2;
  color: #606060;
  margin: 0 0 8px;
}

.container.disabled .label {
  color: #c0c0c0;
}

.buttonWrapper {
  position: relative;
  width: 100%;
}

.button {
  position: relative;
  display: flex;
  align-items: center;
  font-family: "IBM Plex Sans", "Helvetica Neue", Arial, san-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 1;
  text-align: left;
  color: #212121;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  outline: none;
  padding: 10px 16px;
  cursor: pointer;
  width: 100%;
}

.clearButton {
  position: absolute;
  top: 50%;
  right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  transform: translateY(-50%);
  cursor: pointer;
}

.clearIcon {
  color: #606060;
  margin: 0 0 0 auto;
}

.clearIcon:hover {
  color: #212121;
}

.panel {
  position: absolute;
  top: calc(100% + 8px);
  width: 100%;
  z-index: 1;
}

.spacer {
  height: 8px;
}

.dropdownWrapper {
  box-shadow: 0 8px 32px rgba(33, 33, 33, 0.16),
    0 1px 4px rgba(33, 33, 33, 0.16);
  border-radius: 6px;
  max-height: 360px;
  overflow-y: scroll;
}

.dropdown {
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
  border-radius: 6px;
  margin: 0;
  padding: 16px;
  width: 100%;
  list-style: none;
  overflow: hidden;
}

.addIcon,
.addSpan {
  color: #606060;
}

.container.disabled .addIcon,
.container.disabled .addSpan {
  color: #c0c0c0;
}

.addIcon {
  margin: 0 10px 0 0;
}

.dropdownItem {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.iconOption {
  width: 34px;
  height: 34px;
}
