.container {
  padding: 20px 24px 16px;
}

.description {
  font-family: "IBM Plex Sans", "Helvetica Neue", Arial, san-serif;
  font-weight: 400;
  font-size: 14px;
  color: #212121;
  margin: 0 0 16px;
}

.description span {
  font-weight: 600;
}

.buttonWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin: 20px -6px -6px;
}

.buttonWrapper > button {
  margin: 6px;
}
