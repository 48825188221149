.container {
  height: 72px;
  width: 36px;
  background-color: #fff;
  box-shadow: 0px 1px 4px rgba(33, 33, 33, 0.16),
    0px 8px 32px rgba(33, 33, 33, 0.16);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
}

.button {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.button:hover {
  background-color: rgba(33, 33, 33, 0.06);
}
