.container {
  background: #fff;
  flex-shrink: 0;
  z-index: 1;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.absolute.left {
  top: 0;
  left: 0;
}

.absolute.right {
  top: 0;
  right: 0;
}

.shadow {
  box-shadow: 0 8px 32px rgba(33, 33, 33, 0.16),
    0 1px 4px rgba(33, 33, 33, 0.16);
}
