.container {
  display: inline-block;
}

.input {
  position: absolute;
  visibility: hidden;
}

.label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.label:hover .unchecked {
  color: #212121;
}

.iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 6px 0 0;
}

.iconWrapper.bubble {
  border-radius: 50%;
  margin: 0;
  padding: 8px;
}

.iconWrapper.bubble:hover {
  background-color: rgba(33, 33, 33, 0.06);
}

.checked {
  color: #212121;
}

.unchecked {
  color: #bdbdbd;
}

.input:not(:checked) + .label .checked {
  display: none;
}

.input:not(:checked) + .label .unchecked {
  display: inline-block;
}

.input:checked + .label .checked {
  display: inline-block;
}

.input:checked + .label .unchecked {
  display: none;
}

.labelText {
  font-family: "IBM Plex Sans", "Helvetica Neue", Arial, san-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.2;
  color: #212121;
}
