.container {
  display: flex;
  flex-direction: column;
  background-color: #fafafa;
  border-right: 1px solid #e0e0e0;
  height: 100%;
}

.header {
  display: flex;
  align-items: center;
  padding: 7px 12px 0;
}

.header.expanded .hamburger {
  margin: 0 4px 0 0;
}

.headerLogo {
  margin-right: 24px;
}

.hamburger {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  border-radius: 50%;
  padding: 18px;
  cursor: pointer;
}

.hamburger:hover {
  background-color: rgba(33, 33, 33, 0.06);
}

.hamburger > svg {
  color: #606060;
}

.menuItemsContainer {
  display: flex;
  flex-direction: column;
  margin: 32px 12px;
  overflow-y: auto;
}

.menuItem {
  display: flex;
  align-items: center;
  background-color: transparent;
  font-family: "IBM Plex Sans", "Helvetica Neue", Arial, san-serif;
  font-weight: 500;
  font-size: 15px;
  line-height: 1.2;
  text-decoration: none;
  border-radius: 6px;
  margin: 0 0 4px;
  padding: 18px;
  color: #606060;
  cursor: pointer;
}

.menuItem:hover {
  background-color: #eeeeee;
  color: #212121;
}

.menuItem:last-child {
  margin: 0;
}

.menuItem.activeItem {
  color: #3c78ff;
}

.menuItem > svg {
  color: #606060;
}

.menuItem:hover > svg {
  color: #212121;
}

.menuItem.activeItem > svg {
  color: #3c78ff;
}

.menuItem > span {
  margin: 0 0 0 23px;
}

.popoverContainer {
  display: flex;
  justify-content: center;
  border-top: 1px solid #e0e0e0;
  margin-top: auto;
  padding: 20px 15px;
}

.popoverContainer.expanded {
  display: block;
}
