.container {
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  padding: 12px;
  width: 100%;
}

.topContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;
}

.textContainer {
  font-family: "IBM Plex Sans", "Helvetica Neue", Arial, san-serif;
  line-height: 1;
}

.textContainer h2 {
  font-weight: 600;
  font-size: 16px;
  color: #212121;
  margin: 0 0 8px;
}

.textContainer p {
  font-weight: 400;
  font-size: 12px;
  color: #757575;
  margin: 0;
}

.controlsContainer {
  display: flex;
  align-items: center;
  font-family: "IBM Plex Sans", "Helvetica Neue", Arial, san-serif;
  line-height: 1;
  font-weight: 400;
  font-size: 12px;
}

.timeContainer {
  color: #757575;
  margin: 0 50px 0 0;
}

.iconWrapper {
  display: flex;
  align-items: center;
}

.iconWrapper + .iconWrapper {
  margin: 8px 0 0;
}

.icon {
  color: #757575;
  margin: 0 8px 0 0;
}

.alertWrapper {
  margin: 14px 0 0;
}
