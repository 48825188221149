.container {
  padding: 20px 24px 16px;
}

.description {
  font-family: "IBM Plex Sans", "Helvetica Neue", Arial, san-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5;
  color: #424242;
  margin: 0 0 24px;
}

.grid {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(2, 1fr);
}

.buttonWrapper {
  display: flex;
  justify-content: flex-end;
  margin: 40px 0 0;
}

.buttonWrapper > button {
  margin: 4px;
}
