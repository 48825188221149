.container {
  border-radius: 6px;
  padding: 14px 16px;
  display: flex;
  justify-content: space-between;

  font-family: "IBM Plex Sans", "Helvetica Neue", Arial, san-serif;
}

.INFO {
  background: rgba(33, 33, 33, 0.04);
  color: #757575;
}

.INFO_ALT {
  background: rgba(60, 120, 255, 0.08);
  color: #3c78ff;
}

.ERROR {
  background: rgba(183, 28, 28, 0.08);
  color: #d32f2f;
}

.WARNING {
  background: rgba(228, 79, 17, 0.08);
  color: #ed6a14;
}

.SUCCESS {
  background: rgba(0, 95, 30, 0.08);
  color: #178f3c;
}

.content {
  display: flex;
  align-items: center;
}

.contentWithTitle {
  align-items: flex-start;
}

.title {
  font-size: 14px;
  font-weight: 600;
  margin: 0 0 8px;
  padding: 0;
}

.withDescription {
  margin-top: 3px;
}

.description {
  font-size: 14px;
  margin: 0;
  padding: 0;
}

.icon {
  margin-right: 10px;
}

.closeIcon {
  cursor: pointer;
}

.textWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.iconWrapper {
  display: flex;
  align-items: center;
}
