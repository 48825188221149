.container {
  width: 100%;
  display: flex;
  height: 100%;
  flex-direction: column;
}

.workflowContainer {
  overflow-y: scroll;
}

.workflowWrapper {
  padding: 16px;
  width: 640px;
}

.workflowWrapper > div {
  margin-bottom: 16px;
}

.workflowWrapper > div:last-child {
  margin-bottom: 0;
}

.loaderContainer {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.noWorkflowsContainer {
  font-family: "IBM Plex Sans", "Helvetica Neue", Arial, san-serif;
  text-align: center;
  padding: 35px;
}

.noWorkflowsTitle {
  font-weight: 600;
  font-size: 18px;
  color: #212121;
  margin: 0 0 20px;
}

.noWorkflowsDescription {
  font-weight: 400;
  font-size: 14px;
  color: #757575;
}
