.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.label {
  font-family: "IBM Plex Sans", "Helvetica Neue", Arial, san-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.2;
  color: #606060;
  margin: 0 0 8px;
}

.input,
.iconInput {
  font-family: "IBM Plex Sans", "Helvetica Neue", Arial, san-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.2;
  color: #212121;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  outline: none;
  width: 100%;
}

.input:focus,
.iconInput:focus {
  outline: 2px solid #212121;
  outline-offset: -1px;
}

.input:disabled,
.iconInput:disabled {
  background-color: #f5f5f5;
  color: #606060;
}

.input {
  padding: 10px 16px;
}

.iconInput {
  padding: 10px 16px 10px 42px;
}

.iconRight .iconInput {
  padding: 10px 42px 10px 16px;
}

.iconLabel {
  position: relative;
  width: 100%;
}

.iconLabel.iconLeft > svg {
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
}

.iconLabel.iconRight > svg {
  position: absolute;
  top: 50%;
  right: 12px !important;
  transform: translateY(-50%);
}
