.success {
  background-color: #22c55e;
  color: #fff;
}

.info {
  background-color: #e5e5e5;
  color: #212121;
}

.warning {
  background-color: #f57c00;
  color: #fff;
}

.error {
  background-color: #e53935;
  color: #fff;
}

.container {
  display: inline-block;
  font-family: "IBM Plex Sans", "Helvetica Neue", Arial, san-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: 0.005em;
  text-align: center;
  border-radius: 4px;
  padding: 4px 8px;
}
