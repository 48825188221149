.button {
  display: flex;
  align-items: center;
  font-family: "IBM Plex Sans", "Helvetica Neue", Arial, san-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.2;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.buttonText {
  margin: 0 6px 0 0;
}

.button > svg {
  transition: transform 200ms ease-in-out;
}

.open {
  transform: rotate(180deg);
}
