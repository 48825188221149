.container {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e0e0e0;
  padding: 20px 24px;
}

.heading,
.subheading {
  font-family: "IBM Plex Sans", "Helvetica Neue", Arial, san-serif;
  margin: 0;
}

.heading {
  font-size: 24px;
  font-weight: 500;
  color: #212121;
}

.subheading {
  font-size: 14px;
  font-weight: 450;
  color: #757575;
}

.heading + .subheading {
  margin: 8px 0 0;
}

.button {
  align-self: flex-end;
}
