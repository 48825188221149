@use "~ag-grid-community/styles" as ag;
@include ag.grid-styles((
    // use theme parameters where possible
    grid-size: 3px,
    alpine-active-color: #3C78FF1F,
    foreground-color: #606060,
    borders: false,
    row-height: 52px,
    header-background-color: #FFF,
    header-height: 55px,
    checkbox-checked-color: #606060,
    cell-horizontal-padding: 22px
));

.ag-theme-alpine {
    .ag-header-cell-text {
        // or write CSS selectors to make customisations beyond what the parameters support
        width: 100%;
    
        &:last-child {
            border: none
        }
    }

    .ag-header-container {
        border-bottom: 1px solid #E0E0E0;
    }

    input[class^=ag-][type=text] {
        background: rgba(33, 33, 33, 0.04) !important;
        border-radius: 6px;
        border: none;
        outline: none;
        height: 40px;
        font-family: "IBM Plex Sans", "Helvetica Neue", Arial, san-serif;
        font-weight: 400;
        font-size: 14px;
        text-indent: 15px;
        color: #212121;
        letter-spacing: 0.005em;
    }

    .ag-cell {
        font-family: "IBM Plex Sans", "Helvetica Neue", Arial, san-serif;
        font-weight: 400;
        font-size: 14px;
        letter-spacing: 0.005em;
    }

    .ag-react-container {
        height: 100%;
        display: flex;
        align-items: center;
    }

    .cell-overflow-visible {
        overflow: visible;
    }

    .ag-icon-menu::before {
        display: none;
        pointer-events: none;
    }
}