.projectsContainer {
  font-family: "IBM Plex Sans", "Helvetica Neue", Arial, san-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.25;
  color: #212121;
  background-color: #fff;
  padding: 8px 0;
}

.project {
  display: flex;
  align-items: center;
  padding: 8px 38px;
  cursor: pointer;
}

.project:hover {
  background-color: rgba(33, 33, 33, 0.06);
}

.project.activeProject {
  background-color: rgba(33, 33, 33, 0.06);
  padding: 8px 38px 8px 11px;
}

.icon {
  color: #212121;
  margin: 0 14px 0 0;
}
