.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(60, 120, 255, 0.06);
  border-radius: 8px;
  font-family: "IBM Plex Sans", "Helvetica Neue", Arial, san-serif;
  text-align: center;
  color: #3c78ff;
  padding: 24px 35px;
}

.stat {
  font-weight: 700;
  font-size: 42px;
  line-height: 1;
  margin: 8px 0 0;
}

.description {
  font-weight: 500;
  font-size: 15px;
  line-height: 1.2;
  margin: 0;
}
