@font-face {
  font-family: "IBM Plex Sans";
  src: local("IBM Plex Sans Bold Italic"), local("IBMPlexSans-BoldItalic"),
    url("./IBMPlexSans-BoldItalic.woff2") format("woff2"),
    url("./IBMPlexSans-BoldItalic.woff") format("woff"),
    url("./IBMPlexSans-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "IBM Plex Sans";
  src: local("IBM Plex Sans ExtraLight"), local("IBMPlexSans-ExtraLight"),
    url("./IBMPlexSans-ExtraLight.woff2") format("woff2"),
    url("./IBMPlexSans-ExtraLight.woff") format("woff"),
    url("./IBMPlexSans-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "IBM Plex Sans";
  src: local("IBM Plex Sans Italic"), local("IBMPlexSans-Italic"),
    url("./IBMPlexSans-Italic.woff2") format("woff2"),
    url("./IBMPlexSans-Italic.woff") format("woff"),
    url("./IBMPlexSans-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "IBM Plex Sans";
  src: local("IBM Plex Sans ExtraLight Italic"),
    local("IBMPlexSans-ExtraLightItalic"),
    url("./IBMPlexSans-ExtraLightItalic.woff2") format("woff2"),
    url("./IBMPlexSans-ExtraLightItalic.woff") format("woff"),
    url("./IBMPlexSans-ExtraLightItalic.ttf") format("truetype");
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "IBM Plex Sans";
  src: local("IBM Plex Sans Bold"), local("IBMPlexSans-Bold"),
    url("./IBMPlexSans-Bold.woff2") format("woff2"),
    url("./IBMPlexSans-Bold.woff") format("woff"),
    url("./IBMPlexSans-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "IBM Plex Sans";
  src: local("IBM Plex Sans Light Italic"), local("IBMPlexSans-LightItalic"),
    url("./IBMPlexSans-LightItalic.woff2") format("woff2"),
    url("./IBMPlexSans-LightItalic.woff") format("woff"),
    url("./IBMPlexSans-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "IBM Plex Sans";
  src: local("IBM Plex Sans Light"), local("IBMPlexSans-Light"),
    url("./IBMPlexSans-Light.woff2") format("woff2"),
    url("./IBMPlexSans-Light.woff") format("woff"),
    url("./IBMPlexSans-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "IBM Plex Sans";
  src: local("IBM Plex Sans Medium Italic"), local("IBMPlexSans-MediumItalic"),
    url("./IBMPlexSans-MediumItalic.woff2") format("woff2"),
    url("./IBMPlexSans-MediumItalic.woff") format("woff"),
    url("./IBMPlexSans-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "IBM Plex Sans";
  src: local("IBM Plex Sans Regular"), local("IBMPlexSans-Regular"),
    url("./IBMPlexSans-Regular.woff2") format("woff2"),
    url("./IBMPlexSans-Regular.woff") format("woff"),
    url("./IBMPlexSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "IBM Plex Sans";
  src: local("IBM Plex Sans SemiBold Italic"),
    local("IBMPlexSans-SemiBoldItalic"),
    url("./IBMPlexSans-SemiBoldItalic.woff2") format("woff2"),
    url("./IBMPlexSans-SemiBoldItalic.woff") format("woff"),
    url("./IBMPlexSans-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "IBM Plex Sans";
  src: local("IBM Plex Sans Thin Italic"), local("IBMPlexSans-ThinItalic"),
    url("./IBMPlexSans-ThinItalic.woff2") format("woff2"),
    url("./IBMPlexSans-ThinItalic.woff") format("woff"),
    url("./IBMPlexSans-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "IBM Plex Sans";
  src: local("IBM Plex Sans SemiBold"), local("IBMPlexSans-SemiBold"),
    url("./IBMPlexSans-SemiBold.woff2") format("woff2"),
    url("./IBMPlexSans-SemiBold.woff") format("woff"),
    url("./IBMPlexSans-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "IBM Plex Sans";
  src: local("IBM Plex Sans Medium"), local("IBMPlexSans-Medium"),
    url("./IBMPlexSans-Medium.woff2") format("woff2"),
    url("./IBMPlexSans-Medium.woff") format("woff"),
    url("./IBMPlexSans-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "IBM Plex Sans";
  src: local("IBM Plex Sans Thin"), local("IBMPlexSans-Thin"),
    url("./IBMPlexSans-Thin.woff2") format("woff2"),
    url("./IBMPlexSans-Thin.woff") format("woff"),
    url("./IBMPlexSans-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
