.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.container.disabled {
  pointer-events: none;
}

.label {
  font-family: "IBM Plex Sans", "Helvetica Neue", Arial, san-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.2;
  color: #606060;
  margin: 0 0 8px;
}

.container.disabled .label {
  color: #c0c0c0;
}

.button {
  position: relative;
  font-family: "IBM Plex Sans", "Helvetica Neue", Arial, san-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.2;
  text-align: left;
  color: #212121;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  outline: none;
  padding: 10px 42px 10px 16px;
  cursor: pointer;
  width: 100%;
}

.container.disabled .button {
  color: #c0c0c0;
}

.button > svg {
  position: absolute;
  top: 50%;
  right: 14px;
  transform: translateY(-50%);
  color: #606060;
}

.container.disabled .button > svg {
  color: #c0c0c0;
}

.panel {
  position: absolute;
  top: calc(100% + 8px);
  width: 100%;
  z-index: 1;
}

.dropdown {
  font-family: "IBM Plex Sans", "Helvetica Neue", Arial, san-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.2;
  color: #212121;
  background-color: #fff;
  box-shadow: 0 8px 32px rgba(33, 33, 33, 0.16),
    0 1px 4px rgba(33, 33, 33, 0.16);
  border-radius: 6px;
  margin: 0;
  padding: 0;
  width: 100%;
  max-height: 165px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.dropdownItem {
  padding: 12px 16px;
  cursor: pointer;
}

.dropdownItem:hover,
.selected {
  background-color: rgba(33, 33, 33, 0.06);
}

.spacer {
  height: 8px;
}
